import React from "react";
import "../css/ConfirmationDialog.css";
function ConfirmationDialog({
	open,
	onClose,
	dialogText,
	confirmLabel = "Continue",
	confirmAction,
	cancelLabel = "Cancel",
	cancelAction,
}) {
	// Check if dialogText contains "this Webl."
	const hasBreakLine = dialogText.includes("this Webl.");

	// If it contains "this Webl.", add a break line
	const updatedDialogText = hasBreakLine
		? dialogText.replace("this Webl.", "this Webl.\n")
		: dialogText;

	return (
		<>
			{open && (
				<div
					className="custom-dialog"
					onClick={(e) =>
						e.target.className === "custom-dialog" && onClose()
					}
				>
					<div className="custom-dialog-content custom-confirmation-dialog-content">
						{updatedDialogText}
						<div className="confirmation-buttons">
							<button
								className="cancel_btn"
								onClick={cancelAction}
							>
								{cancelLabel}
							</button>
							<button
								className="confirm_btn"
								onClick={confirmAction}
							>
								{confirmLabel}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ConfirmationDialog;

function RestrictedWebl({ weblDomain }) {
	return (
		<div
			style={{
				display: "flex",
				position: "absolute",
				zIndex: "1050",
				backgroundColor: "var(--webl_green)",
				borderLeft: "1px solid var(--webl_black)",
				borderRight: "1px solid var(--webl_black)",
				borderTop: "1px solid var(--webl_black)",
				width: "calc(1000px - 300px)",
				justifyContent: "center",
				alignItems: "center",
				padding: "5px",
				top: "5px",
			}}
		>
			<div style={{ textAlign: "center" }}>
				<p style={{ color: "var(--webl_lightgray)" }}>
					<b style={{ color: "var(--webl_lightgray)" }}>
						This Webl is accessible only via embedded code and
						restricted to the owner's view.
					</b>
					<br />
					Modify this by clicking "Get Code" in{" "}
					<a
						href={"/updateWebl/" + weblDomain}
						style={{
							color: "white",
							cursor: "pointer",
							textDecoration: "underline",
						}}
					>
						Settings
					</a>
					.
				</p>
			</div>
		</div>
	);
}

export default RestrictedWebl;

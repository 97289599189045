import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import WeblCreation from "../pages/WeblCreation";
import ViewWebl from "../pages/ViewWebl";
import PreviewWebl from "../pages/PreviewWebl";
import icon from "../images/favicons/favicon.ico";
import ErrorPage from "../pages/ErrorPage";
import PricingPage from "../pages/PricingPage";
import StripeSuccessPage from "../pages/StripeSuccessPage";
import "../css/text-styles.css";

const context = require.context("../css/Themes/", false, /\.css$/);
context.keys().forEach(context);

function App() {
	useEffect(() => {
		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", icon);
	}, []);

	return (
		<BrowserRouter>
			<>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="uploadFileApi/" element={<Home />} />
					<Route
						path="preview/:weblDomain"
						element={<PreviewWebl />}
					/>
					<Route path="weblCreation/" element={<WeblCreation />} />
					<Route
						path="updateWebl/:weblDomain"
						element={<WeblCreation />}
					/>
					<Route
						path="stripeSuccess/:sessionId"
						element={<StripeSuccessPage />}
					/>
					<Route path="pricing" element={<PricingPage />} />
					<Route path="error/:errorType?" element={<ErrorPage />} />
					<Route path="/:weblDomain" element={<ViewWebl />} />
				</Routes>
			</>
		</BrowserRouter>
	);
}

export default App;

import "../../css/WeblCreation/three-buttons.css";
import PublishWeblIcon from "../../images/icons/publish.svg";
import SaveDraftIcon from "../../images/tool icons/WeblCreation/save_draft.svg";
import GetCodeIcon from "../../images/tool icons/WeblCreation/get_code.svg";
import PreviewIcon from "../../images/icons/view.svg";

import IframeDialog from "./IframeDialog.jsx";

function ThreeButtons({
	setCreateWeblButtonClicked,
	setPreviewLoading,
	createWebl,
	setIframeDialogIsOpen,
	iframeDialogIsOpen,
	weblid,
	iframeDimensions,
	setIframeDimensions,
	weblCreationMode,
	handleSaveDraft,
}) {
	return (
		<div className="three-buttons-container">
			<button
				id="publish-webl-button"
				onClick={() => {
					setCreateWeblButtonClicked(true);
					createWebl({ publicAccess: "published" });
				}}
			>
				<img
					className="icon"
					src={PublishWeblIcon}
					alt="Publish Webl Icon"
				/>
				<div>
					{(weblCreationMode === "create" ? "Publish" : "Update") +
						" Webl"}
				</div>
			</button>
			<button
				className="secondary-button"
				onClick={() => {
					setPreviewLoading(true);
					createWebl({ publicAccess: "preview" });
				}}
			>
				Preview Webl{" "}
				<img className="icon" src={PreviewIcon} alt="Preview Icon" />
			</button>
			<button
				onClick={() => {
					setCreateWeblButtonClicked(true);
					handleSaveDraft();
				}}
				className="secondary-button"
			>
				{(weblCreationMode === "create" ? "Save" : "Revert to") +
					" Draft"}
				<img
					className="icon"
					src={SaveDraftIcon}
					alt="Save Draft Icon"
				/>
			</button>
			<button
				onClick={() => setIframeDialogIsOpen(!iframeDialogIsOpen)}
				className="secondary-button"
			>
				Get Code{" "}
				<img className="icon" src={GetCodeIcon} alt="Get Code Icon" />
			</button>
			<IframeDialog
				open={iframeDialogIsOpen}
				onClose={() => setIframeDialogIsOpen(false)}
				weblInfo={{
					weblid: weblid,
				}}
				iframeDimensions={iframeDimensions}
				setIframeDimensions={setIframeDimensions}
				createWebl={createWebl}
				setCreateWeblButtonClicked={setCreateWeblButtonClicked}
			/>
		</div>
	);
}

export default ThreeButtons;

import "../../css/Home/HomeHeader.css";
import AlmaEconomicsLogo from "../../images/icons/logo forced.svg";
import WeblerLogo from "../../images/Logo/SVG/Webler_White.svg";

export default function HomeHeader() {
	return (
		<div id="home-header-container">
			<a id="home-header-container-left" href="/">
				<img src={WeblerLogo} alt="Webler Logo" />
			</a>
			<a
				id="home-header-container-right"
				href="https://www.almaeconomics.com/"
				target="_blank"
				rel="noreferrer"
			>
				<img src={AlmaEconomicsLogo} alt="Alma Economics Logo" />
			</a>
		</div>
	);
}

import axios from "axios";
import { Client, GraphError } from "@microsoft/microsoft-graph-client";

export const deleteSheets = async (fileId, sheetNames, navigate) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });

		const sheetsResponse = await client
			.api(`/me/drive/items/${fileId}/workbook/worksheets`)
			.get();

		const sheets = sheetsResponse["value"];
		const sheetIdsToDelete = sheets
			.filter((sheet) => sheetNames.includes(sheet["name"]))
			.map((sheet) => sheet.id);

		for (const sheetId of sheetIdsToDelete) {
			await client
				.api(`/me/drive/items/${fileId}/workbook/worksheets/${sheetId}`)
				.delete();
		}
	} catch (error) {
		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);

			if (error instanceof GraphError) {
				if (error.statusCode === 404) {
					navigate("/error");
				}
			} else {
				alert("An unexpected error occurred. Please try again later.");
			}
		}
	}
};

export const readFile = async (fileId, navigate) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });

		const fileResponse = await client
			.api(`/me/drive/items/${fileId}`)
			.get();
		return fileResponse;
	} catch (error) {
		if (error instanceof GraphError) {
			console.error(error);
			if (error.statusCode === 404) {
				navigate("/error");
			}
		}

		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);
			alert("An unexpected error occurred. Please try again later.");
		}
	}
};

export const uploadFile = async (
	fileName,
	mediaContent,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary
) => {
	const response = await axios.get("/api/get_access_token/");
	const accessToken = response.data;
	const authProvider = (done) => done(null, accessToken);
	const client = Client.init({ authProvider });
	const uploadFileResponse = await client
		.api(
			"/me/drive/root:/" +
				process.env.REACT_APP_MODEL_FOLDER +
				"/" +
				userFolder +
				"/" +
				fileName +
				":/content?@microsoft.graph.conflictBehavior=rename"
		)
		.put(mediaContent);
	const responseSheets = await client
		.api(
			"/me/drive/items/" + uploadFileResponse.id + "/workbook/worksheets/"
		)
		.get();
	const sheetNamesDictionary = {};
	responseSheets.value.forEach((worksheet) => {
		sheetNamesDictionary[worksheet.name] = worksheet.id;
	});
	setSheetNamesDictionary(sheetNamesDictionary);
	setFileID(uploadFileResponse.id);
	setFile(mediaContent);
	setShowLoading(true);
	return true;
};

export const downloadFile = async (sessionInfo, fileId, navigate) => {
	try {
		const response = await sessionInfo.client
			.api("/me/drive/items/" + fileId + "/content")
			.version("v1.0")
			.header("workbook-session-id", sessionInfo.sessionId)
			.responseType("arraybuffer")
			.get();

		const url = window.URL.createObjectURL(
			new Blob([response], { type: "application/octet-stream" })
		);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "Webl.xlsx");
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);

			if (error.statusCode === 404) {
				navigate("/error");
			} else {
				alert("An unexpected error occurred. Please try again later.");
			}
		}
	}
};

export const createFolder = async (userSub, navigate) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });
		const driveItem = {
			name: userSub,
			folder: {},
			"@microsoft.graph.conflictBehavior": "fail",
		};

		await client
			.api(
				"/me/drive/root:/" +
					process.env.REACT_APP_MODEL_FOLDER +
					":/children"
			)
			.post(driveItem);
	} catch (error) {
		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);

			if (error.statusCode === 404) {
				navigate("/error");
			} else {
				alert("An unexpected error occurred. Please try again later.");
			}
		}
	}
};

export const previewFolder = async (
	userSub,
	setuserFilesIdsOnedrive,
	navigate
) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });

		const mainFolderContent = await client
			.api(
				"/me/drive/root:/" +
					process.env.REACT_APP_MODEL_FOLDER +
					":/children"
			)
			.get();

		let userSubId = null;
		for (let i = 0; i < mainFolderContent.value.length; i++) {
			if (
				mainFolderContent.value[i].name === userSub.split("auth0|")[1]
			) {
				userSubId = mainFolderContent.value[i].id;
				break;
			}
		}

		const userFolderContent = await client
			.api("me/drive/items/" + userSubId + "/children")
			.get();

		const userFilesIdsOnedrive = userFolderContent.value.map(
			(obj) => obj.id
		);
		setuserFilesIdsOnedrive(userFilesIdsOnedrive);
	} catch (error) {
		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);

			if (error.statusCode === 404) {
				navigate("/error");
			} else {
				alert("An unexpected error occurred. Please try again later.");
			}
		}
	}
};

export const searchFile = async (
	fileName,
	userFolder,
	setFileFound,
	setWeblToDelete
) => {
	const response = await axios.get("/api/get_access_token/");
	const accessToken = response.data;
	const authProvider = (done) => done(null, accessToken);
	const client = Client.init({ authProvider });

	try {
		const responseSearch = await client
			.api(
				"/me/drive/root:/" +
					process.env.REACT_APP_MODEL_FOLDER +
					"/" +
					userFolder +
					"/" +
					fileName
			)
			.get();
		setWeblToDelete(responseSearch["id"]);
		setFileFound(200);
	} catch (error) {
		setFileFound(404);
	}
};

export const deleteFile = async (fileId, navigate) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });

		// Attempt to delete the file
		await client.api("/me/drive/items/" + fileId).delete();
	} catch (error) {
		if (error.response) {
			// The client was given an error response (5xx, 4xx)
			console.error(
				error.response.status + " " + error.response.statusText
			);

			if (error.response.status === 404) {
				navigate("/error");
			} else {
				// Display a user-friendly error message for other errors
				alert("An error occurred. Please try again later.");
			}
		} else if (error.request) {
			// The client never received a response, and the request was never left
			console.error(error.request);
			alert("An error occurred. Please check your internet connection.");
		} else {
			// Anything else
			console.error("error", error.message);

			if (error.statusCode === 404) {
				navigate("/error");
			} else {
				alert("An unexpected error occurred. Please try again later.");
			}
		}
	}
};

export const createSession = async (
	setSessionInfo,
	fileId,
	peristentChanges
) => {
	try {
		const response = await axios.get("/api/get_access_token/");
		const accessToken = response.data;
		const authProvider = (done) => done(null, accessToken);
		const client = Client.init({ authProvider });
		const workbookSessionInfo = { persistChanges: peristentChanges };
		const sessionResponse = await client
			.api("/me/drive/items/" + fileId + "/workbook/createSession")
			.post(workbookSessionInfo);
		const sessionId = sessionResponse.id;
		setSessionInfo({
			client: client,
			sessionId: sessionId,
		});
	} catch (error) {
		console.error(error);
		if (error instanceof GraphError) {
			console.error(error.statusCode);
			console.error(error.requestId);
		}
	}
};

export const readFormat = async (
	sessionInfo,
	setCellFormat,
	sheetId,
	range
) => {
	const cellResponse = await sessionInfo.client
		.api(
			"/me/drive/items/" +
				sessionInfo.fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/range(address='" +
				range +
				"')"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();
	setCellFormat(cellResponse.numberFormat);
};

export const setCellValue = async (
	sessionInfo,
	fileId,
	sheetId,
	row,
	column,
	value
) => {
	const data = {
		values: [[value]],
	};

	await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/cell(row=" +
				row +
				",column=" +
				column +
				")"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.patch(data, (err, res) => {
			if (err) {
				console.error(err);
				return;
			}
		});
};

export const getCellValue = async (
	sessionInfo,
	fileId,
	setCellValues,
	sheetId,
	row,
	column
) => {
	const cellResponse = await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/cell(row=" +
				row +
				",column=" +
				column +
				")"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();
	setCellValues(cellResponse.text);
};

export const setRangeValue = async (
	sessionInfo,
	fileId,
	sheetId,
	range,
	value
) => {
	const data = {
		values: [[value]],
	};
	await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/range(address='" +
				range +
				"')/values"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.patch(data, (err, res) => {
			if (err) {
				console.error(err);
				return;
			}
		});
};

export const getRangeValue = async (sessionInfo, fileId, sheetId, range) => {
	const cellResponse = await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/range(address='" +
				range +
				"')"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();

	return cellResponse.text;
};

export const keepSessionAlive = async (sessionInfo, fileId, sheetId) => {
	try {
		await sessionInfo.client
			.api(
				"/me/drive/items/" + fileId + "/workbook/worksheets/" + sheetId
			)
			.version("v1.0")
			.header("workbook-session-id", sessionInfo.sessionId)
			.get();
	} catch (error) {
		console.error(error);
		if (error instanceof GraphError) {
			console.error(console.statusCode);
		}
	}
};

export const closeSession = async (sessionInfo, fileId) => {
	await sessionInfo.client
		.api("/me/drive/items/" + fileId + "/workbook/closeSession")
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.post();
};

export const getChartNames = async (sessionInfo, fileId, sheetId) => {
	const charts = await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/charts/"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();
	return charts.value.map((chart) => chart.name);
};

export const getChartImage = async (
	sessionInfo,
	fileId,
	sheetId,
	chartName
) => {
	const request = {
		method: "GET",
		url:
			"/me/drive/items/" +
			fileId +
			"/workbook/worksheets/" +
			sheetId +
			"/charts/" +
			chartName +
			"/image",
	};

	const response = await sessionInfo.client
		.api(request.url)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();
	return response.value;
	// await new Promise((resolve) => setTimeout(resolve, 100)); // Add a delay of 1 second
};

export const getChart = async (
	sessionInfo,
	fileId,
	setChart,
	sheetId,
	chartName
) => {
	const chartResponse = await sessionInfo.client
		.api(
			"/me/drive/items/" +
				fileId +
				"/workbook/worksheets/" +
				sheetId +
				"/charts/" +
				chartName +
				"/image(width=1000,height=1000)"
		)
		.version("v1.0")
		.header("workbook-session-id", sessionInfo.sessionId)
		.get();
	setChart(chartResponse.value);
};

import WeblerLogo from "../../images/Logo/SVG/Webler_Original.svg";
import LogoutButton from "../../components/LogoutButton.jsx";
import CornerIcon from "../../images/icons/corner.svg";
// import { useEffect } from "react";

import "../../css/Webl/WeblHeader.css";
function WeblHeader({
	user,
	weblTitle,
	forWeblCreationPage = false,
	removeWeblerLogo,
}) {
	// useEffect(() => {
	// 	const urlContainsWeblCreation =
	// 		window.location.href.includes("weblCreation");

	// 	if (urlContainsWeblCreation) {
	// 		const headerContainer = document.getElementById(
	// 			"webl-header-container"
	// 		);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<div id="webl-header-container">
			<div id="webl-header">
				<div id="webl-header-left-border"> </div>
				<div id="webl-header-inner-container">
					<a
						id="webl-header-left-item"
						// href={
						// 	process.env.NODE_ENV === "development"
						// 		? "http://app.localhost:3000/"
						// 		: "https://app.webler.io/"
						// }
						href="/"
						rel="noreferrer"
					>
						{removeWeblerLogo ? null : (
							<img src={WeblerLogo} alt="Webler Logo" />
						)}
					</a>
					{user && (
						<div id="webl-header-right-item">
							<LogoutButton />
						</div>
					)}
				</div>
				<div id="webl-header-right-border"> </div>
			</div>
			{!forWeblCreationPage && (
				<div id="webl-outline-top">
					<div id="webl-outline-top-left">
						<img src={CornerIcon} alt="corner-icon" />
					</div>
					<div id="webl-outline-title">
						{weblTitle && <span>{weblTitle}</span>}
					</div>
					<div id="webl-outline-top-right" />
				</div>
			)}
		</div>
	);
}

export default WeblHeader;

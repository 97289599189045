import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./components/App";
import { ThemeProvider, createTheme } from "@mui/material";
import "./css/index.css";
import "./fonts/Arial Nova/ArialNova.ttf";
import "./fonts/Arial Nova/ArialNova-Bold.ttf";
import "./fonts/Arial Nova/ArialNova-Italic.ttf";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const THEME = createTheme({
	typography: {
		fontFamily: `"Arial Nova", sans-serif`,
	},
	palette: {
		primary: {
			main: "#343739",
		},
		secondary: {
			main: "#395643",
		},
	},
});

ReactDOM.render(
	<ThemeProvider theme={THEME}>
		<React.StrictMode>
			<Auth0Provider
				domain={domain}
				clientId={clientId}
				useRefreshTokens={true}
				cacheLocation="localstorage"
				authorizationParams={{
					redirect_uri: window.location.origin,
				}}
			>
				<App />
			</Auth0Provider>
		</React.StrictMode>
	</ThemeProvider>,
	document.getElementById("root")
);

import Toggle from "react-styled-toggle";
import "../css/CustomSwitch.css";

export default function CustomSwitch({
	checked,
	onChange,
	height,
	width,
	backgroundColorChecked,
	translate,
}) {
	return (
		<Toggle
			onChange={onChange}
			sliderHeight={6}
			sliderWidth={6}
			translate={translate}
			height={height}
			width={width}
			backgroundColorChecked={backgroundColorChecked}
			backgroundColorUnchecked="white"
			checked={checked}
			readOnly
		/>
	);
}

import "../../css/Home/HomeFooter.css";

export default function HomeFooter() {
	return (
		<div id="home-footer-container">
			<a
				href="https://www.almaeconomics.com/"
				target="_blank"
				rel="noreferrer"
			>
				Powered by <u>Alma Economics</u>
			</a>
			<p>&copy; 2024 All rights reserved.</p>
		</div>
	);
}

import React, { useEffect } from "react";
import "../../css/WeblCreation/LuckysheetStyleMask.css";
import CornerIcon from "../../images/icons/corner.svg";

export default function LuckysheetStyleMask() {
	useEffect(() => {
		const updateWidth = () => {
			const luckysheetElement = document.getElementById("luckysheet");
			const columnsHeaderContainer = document.querySelector(
				".columns-header-border"
			);

			if (luckysheetElement && columnsHeaderContainer) {
				const luckysheetWidth = luckysheetElement.offsetWidth;

				if (luckysheetWidth > 0) {
					columnsHeaderContainer.style.width = `${
						luckysheetWidth - 6
					}px`;
				} else {
					// Retry after 5 seconds
					setTimeout(() => {
						const retryLuckysheetWidth =
							luckysheetElement.offsetWidth;
						columnsHeaderContainer.style.width = `${
							retryLuckysheetWidth - 6
						}px`;
					}, 500);
				}
			}
		};

		updateWidth();

		window.addEventListener("resize", updateWidth);

		return () => {
			window.removeEventListener("resize", updateWidth);
		};
	});

	return (
		<>
			<div className="rows-header-border" />
			<div className="columns-header-border" />
			<div className="corner-icon-container">
				<img src={CornerIcon} alt="corner-icon" />
			</div>
		</>
	);
}

import { useState } from "react";
import { Tooltip } from "@mui/material";
import "../../css/ConfirmationDialog.css";
import "../../css/WeblCreation/IframeDialog.css";
import Snackbar from "../Snackbar.jsx";
import CopyIcon from "../../images/tool icons/WeblCreation/save_draft.svg";
import { TextField } from "@mui/material";

function IframeDialog({
	open,
	onClose,
	weblInfo,
	iframeDimensions,
	setIframeDimensions,
	createWebl,
	setCreateWeblButtonClicked,
}) {
	const [copied, setCopied] = useState(false);
	let iframeComponent = null;
	if (weblInfo) {
		iframeComponent =
			'<iframe src="' +
			window.location.origin +
			"/" +
			weblInfo.weblid +
			'" frameborder="0" style="width: ' +
			iframeDimensions.width +
			"; height: " +
			iframeDimensions.height +
			';"></iframe>';
	}
	const handleCopyClick = () => {
		navigator.clipboard.writeText(iframeComponent);
		setCopied(true);
	};
	const handleCloseSnackbar = () => {
		setCopied(false);
	};
	return (
		open && (
			<div
				className="custom-dialog"
				onClick={(e) =>
					e.target.className === "custom-dialog" && onClose()
				}
			>
				<div
					className="custom-dialog-content"
					id="iframe-dialog-content"
				>
					{/* <input id="iframe-input" value={iframeComponent} /> */}

					<TextField
						id="iframe-input"
						size="small"
						type="text"
						fullWidth
						value={iframeComponent}
					/>
					<div id="iframe-copy-icon">
						<button
							id="iframe-copy-icon-button"
							onClick={handleCopyClick}
						>
							<img
								className="icon"
								src={CopyIcon}
								alt="Save Draft Icon"
							/>
						</button>
						<Snackbar
							open={copied}
							autoHideDuration={2000}
							onClose={handleCloseSnackbar}
							message="Embed code was copied to clipboard!"
						/>
					</div>
					{/* 
					<input
						id="iframe-width-input"
						placeholder="Width (px)"
						type="number"
						onChange={(e) => {
							const currentIframeDimensions = iframeDimensions;
							currentIframeDimensions.width =
								e.target.value + "px";
							setIframeDimensions(currentIframeDimensions);
						}}
					/> */}
					<TextField
						id="iframe-width-input"
						size="small"
						type="number"
						fullWidth
						placeholder="Width (px)"
						onChange={(e) => {
							const currentIframeDimensions = iframeDimensions;
							currentIframeDimensions.width =
								e.target.value + "px";
							setIframeDimensions(currentIframeDimensions);
						}}
						style={{ marginLeft: 10 }}
					/>
					<TextField
						id="iframe-height-input"
						size="small"
						type="number"
						fullWidth
						placeholder="Height (px)"
						onChange={(e) => {
							const currentIframeDimensions = iframeDimensions;
							currentIframeDimensions.height =
								e.target.value + "px";
							setIframeDimensions(currentIframeDimensions);
						}}
						style={{ marginLeft: 10 }}
					/>
					<div>
						<Tooltip title="Copy the code and create Webl.">
							<button
								onClick={() => {
									handleCopyClick();
									onClose();
									setCreateWeblButtonClicked(true);
									createWebl({ publicAccess: "iframe" });
								}}
								id="create-iframe-button"
								className="confirm_btn"
							>
								Create Webl
							</button>
						</Tooltip>
					</div>
				</div>
			</div>
		)
	);
}

export default IframeDialog;

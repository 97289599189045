function hasNonWhiteBackgroundColor(id) {
	const element = document.getElementById(id);

	if (!element) {
		return false;
	}

	const computedStyle = window.getComputedStyle(element);
	const backgroundColor = computedStyle.backgroundColor;

	if (
		backgroundColor === "rgba(0, 0, 0, 0)" ||
		backgroundColor === "transparent" ||
		backgroundColor === "white" ||
		backgroundColor.toLowerCase() === "#ffffff"
	) {
		return false;
	}

	return true;
}

export function getTableCornerIds(table, sheetToShow, selectedElementsRange) {
	var cells = table.getElementsByTagName("td");
	let leftest_column_index = Number.POSITIVE_INFINITY;
	let rightest_column_index = Number.NEGATIVE_INFINITY;
	let toppest_row_index = Number.POSITIVE_INFINITY;
	let bottomest_row_index = Number.NEGATIVE_INFINITY;
	let leftest_cell_id, rightest_cell_id, toppest_cell_id, bottomest_cell_id;
	cells.forEach((cell) => {
		if (
			cell.style.display !== "none" &&
			(cell.innerText !== "" ||
				hasNonWhiteBackgroundColor(cell.id) ||
				cell.getElementsByTagName("img").length > 0)
		) {
			const current_row_index = parseInt(cell.id.split("_")[0]);
			const current_column_index = parseInt(cell.id.split("_")[1]);
			if (
				current_row_index >=
					selectedElementsRange[sheetToShow][0]["row"][0] &&
				current_row_index <=
					selectedElementsRange[sheetToShow][0]["row"][1] &&
				current_column_index >=
					selectedElementsRange[sheetToShow][0]["column"][0] &&
				current_column_index <=
					selectedElementsRange[sheetToShow][0]["column"][1]
			) {
				if (current_row_index < toppest_row_index) {
					toppest_row_index = current_row_index;
					toppest_cell_id = cell.id;
				}
				if (current_row_index > bottomest_row_index) {
					bottomest_row_index = current_row_index;
					bottomest_cell_id = cell.id;
				}
				if (current_column_index < leftest_column_index) {
					leftest_column_index = current_column_index;
					leftest_cell_id = cell.id;
				}
				if (current_column_index > rightest_column_index) {
					rightest_column_index = current_column_index;
					rightest_cell_id = cell.id;
				}
			}
		}
	});

	return [
		leftest_cell_id,
		rightest_cell_id,
		toppest_cell_id,
		bottomest_cell_id,
	];
}

export function getLargestCellDimensions(table) {
	let largestCellHeight = 0;
	let largestCellWidth = 0;

	// Get all table cells
	const cells = table.getElementsByTagName("td");

	// Iterate over each cell to find the largest dimensions
	for (let i = 0; i < cells.length; i++) {
		const cell = cells[i];
		const cellHeight = cell.clientHeight;
		const cellWidth = cell.clientWidth;

		// Update largest cell height and width if necessary
		if (cellHeight > largestCellHeight) {
			largestCellHeight = cellHeight;
		}
		if (cellWidth > largestCellWidth) {
			largestCellWidth = cellWidth;
		}
	}

	return [largestCellHeight, largestCellWidth];
}

export function getImageHeight(element) {
	const images = element.querySelectorAll("img");
	let maxHeight = 0;
	images.forEach((image) => {
		const { height } = image.getBoundingClientRect();
		maxHeight = Math.max(maxHeight, height);
	});

	return maxHeight;
}

export function getImageWidth(element) {
	const images = element.querySelectorAll("img");
	let maxWidth = 0;
	images.forEach((image) => {
		const { width } = image.getBoundingClientRect();
		maxWidth = Math.max(maxWidth, width);
	});

	return maxWidth;
}
export function cleanDropdowns(inputRanges, dropdownsLocal) {
	Object.keys(dropdownsLocal).forEach((sheet) => {
		dropdownsLocal[sheet] = dropdownsLocal[sheet].filter(
			(dropdown) =>
				inputRanges[sheet].filter(
					(input) =>
						input["row"][0] >= dropdown["cell"].split("_")[0] &&
						input["row"][1] <= dropdown["cell"].split("_")[0] &&
						input["column"][0] >= dropdown["cell"].split("_")[1] &&
						input["column"][1] <= dropdown["cell"].split("_")[1]
				).length > 0
		);
	});
	return dropdownsLocal;
}

export function numberToCol(num) {
	num++;
	let str = "",
		q,
		r;
	while (num > 0) {
		q = (num - 1) / 26;
		r = (num - 1) % 26;
		num = Math.floor(q);
		str = String.fromCharCode(65 + r) + str;
	}
	return str;
}

import {
	IconButton,
	TextField,
	DialogContentText,
	Dialog,
	DialogContent,
	FormControl,
	FormHelperText,
} from "@mui/material";
import InputRounded from "@mui/icons-material/Input";

function WeblPasswordInput({
	passwordInputCorrect,
	setPasswordInputValue,
	submitPassword,
}) {
	return (
		<FormControl style={{ display: "flex" }}>
			<Dialog open={true}>
				<DialogContent>
					<DialogContentText
						style={{
							marginBottom: "30px",
						}}
					>
						Please type your password to access this Webl.
					</DialogContentText>

					<div style={{ display: "flex" }}>
						<TextField
							fullWidth
							id="password-input"
							label="Webl Password"
							size="small"
							type="password"
							error={
								passwordInputCorrect !== null &&
								passwordInputCorrect === false
							}
							onChange={(e) =>
								setPasswordInputValue(e.target.value)
							}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									setPasswordInputValue(e.target.value);
									submitPassword();
								}
							}}
						/>
						<IconButton
							edge="end"
							aria-label="Submit Password"
							style={{ marginLeft: 10 }}
							onClick={submitPassword}
							disableRipple
							disableFocusRipple
							disableTouchRipple
						>
							<InputRounded />
						</IconButton>
					</div>
					<FormHelperText error>
						{passwordInputCorrect !== null &&
						passwordInputCorrect === false
							? "Incorrect Password"
							: " "}
					</FormHelperText>
				</DialogContent>
			</Dialog>
		</FormControl>
	);
}

export default WeblPasswordInput;

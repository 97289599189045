import CustomSwitch from "../CustomSwitch";
import { useState } from "react";
import "../../css/WeblCreation/AutoSelectionDialog.css";
import { useEffect } from "react";

export default function AutoSelectionDialog({
	setSelectSuggestions,
	setDoNotShowAgainAutoselectionDialog,
	selectSuggestions,
	doNotShowAgainAutoselectionDialog,
	cancelAction,
	closeAction,
}) {
	const [selectSuggestionsTmp, setSelectSuggestionsTmp] =
		useState(selectSuggestions);
	const [
		doNotShowAgainAutoselectionDialogTmp,
		setDoNotShowAgainAutoselectionDialogTmp,
	] = useState(doNotShowAgainAutoselectionDialog);

	useEffect(() => {
		setDoNotShowAgainAutoselectionDialog(
			doNotShowAgainAutoselectionDialogTmp
		);
		setSelectSuggestions(selectSuggestionsTmp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doNotShowAgainAutoselectionDialogTmp, selectSuggestionsTmp]);

	return (
		<div
			className="custom-dialog"
			onClick={(e) =>
				e.target.className === "custom-dialog" && cancelAction()
			}
		>
			<div className="custom-dialog-content custom-confirmation-dialog-content">
				Webler autoselects which cells to make visible, hidden, and the
				cells to designate as inputs. Toggle the button below to disable
				this feature.
				<div id="select-suggestions-switch-container">
					<CustomSwitch
						checked={selectSuggestionsTmp}
						onChange={() =>
							setSelectSuggestionsTmp(!selectSuggestionsTmp)
						}
						height={16}
						backgroundColorChecked={"#8cc63f"}
						width={40}
						translate={18}
					/>
					<span
						onClick={() =>
							setSelectSuggestionsTmp(!selectSuggestionsTmp)
						}
					>
						Apply autoselect to my upload
					</span>
				</div>
				<div id="save-option-switch-container">
					<CustomSwitch
						checked={doNotShowAgainAutoselectionDialogTmp}
						onChange={() =>
							setDoNotShowAgainAutoselectionDialogTmp(
								!doNotShowAgainAutoselectionDialogTmp
							)
						}
						height={16}
						backgroundColorChecked={"#8cc63f"}
						width={40}
						translate={18}
					/>
					<span
						onClick={() =>
							setDoNotShowAgainAutoselectionDialogTmp(
								!doNotShowAgainAutoselectionDialogTmp
							)
						}
					>
						Do not show this message again
					</span>
				</div>
				<div className="confirmation-buttons">
					<button className="cancel_btn" onClick={cancelAction}>
						Cancel
					</button>
					<button
						className="confirm_btn"
						onClick={() => {
							closeAction();
						}}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

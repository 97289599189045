import LoadingAnimation from "./LoadingAnimation.jsx";
import "../css/CustomSwitch.css";

export default function FullPageLoading({ descTextLine1, descTextLine2 }) {
	return (
		<div className="full-page-animation-container">
			<LoadingAnimation
				descTextLine1={descTextLine1}
				descTextLine2={descTextLine2}
			/>
		</div>
	);
}

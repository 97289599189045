import { useEffect, useState, useCallback } from "react";
import "../../css/Home/WeblControlPanel.css";
// import GalleryIcon from "../../images/icons/gallery 2.svg";
import WeblControlPanelList from "./WeblControlPanelList.jsx";
import WeblControlPanelGallery from "./WeblControlPanelGallery.jsx";
import LoadingAnimation from "../../components/LoadingAnimation.jsx";
import { deleteWebl, sortWebls } from "../../functions/HomeFunctions";
import { deleteFile } from "../../functions/spreadsheetFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function WeblControlPanel() {
	const navigate = useNavigate();
	const { user } = useAuth0();
	// const [galleryViewIsEnabled, getGalleryViewIsEnabled] = useState(false);
	const [showLoading, setShowLoading] = useState(true);
	const [sortWeblsBy, setSortWeblsBy] = useState("date");
	const [sortWeblsOrder, setSortWeblsOrder] = useState("desc");
	const [weblList, setWeblList] = useState(null);
	const [weblToDelete, setWeblToDelete] = useState(0);

	/**
	 * Retrieves user-specific Webls and related information for the current user.
	 * It makes an Axios GET request to fetch user Webls, updates the Webl list, user files IDs database,
	 * and sets the 'addinUpload' state. It also handles error responses, such as Service Unavailable or
	 * internal server errors, and may navigate the user to an error page in case of certain errors.
	 */
	useEffect(() => {
		axios({
			url: "/getUserWebls/" + user.sub,
		})
			.then((response) => {
				setWeblList(JSON.parse(response.data));
				setShowLoading(false);
				// setAddinUpload(true);
			})
			.catch((error) => {
				if (error.response) {
					// The client was given an error response (5xx, 4xx)
					console.error(
						error.response.status + error.response.statusText
					);
					if (error.response.status === 404) {
						navigate("/error");
					}
				} else if (error.request) {
					// The client never received a response, and the request was never left
					console.error(error.request);
				} else {
					// Anything else
					console.error("error", error.message);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.sub]);

	/**
	 * A callback function used to delete a Webl item.
	 */
	const deleteWeblCallback = useCallback(
		(weblid = null) => {
			!weblid && (weblid = weblToDelete);
			deleteWebl(
				deleteFile,
				weblList,
				setWeblList,
				weblid,
				setWeblToDelete,
				navigate
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[weblToDelete, weblList]
	);
	useEffect(() => {
		console.log(weblList);
	});
	return (
		<>
			<div id="webl-control-panel-container">
				<div id="webl-control-panel-text-container">
					<span className="custom-title">My Webls</span>
					<div id="webl-control-panel-text-description-container">
						{/* <span className="custom-subtitle">
							{!weblList || weblList.length > 0
								? ""
								: "No Webls created..."}
						</span> */}
						{/* {!weblList ||
							(weblList.length > 0 && (
								<button
									onClick={() =>
										getGalleryViewIsEnabled(
											!galleryViewIsEnabled
										)
									}
								>
									<img
										src={GalleryIcon}
										alt="gallery icon"
										style={{ width: 26, height: 26 }}
									/>
									<span>View </span>
									{!galleryViewIsEnabled ? "gallery" : "list"}
								</button>
							))} */}
					</div>
				</div>
				{(!weblList || showLoading) && (
					<LoadingAnimation
						descTextLine1="Loading your Webls."
						descTextLine2="This may take a few seconds."
					/>
				)}
				{weblList && !showLoading && weblList.length === 0 && (
					<span className="custom-subtitle">
						You've not created any Webls. Upload a spreadsheet or
						add a OneDrive link to create your first Webl.
					</span>
				)}
				{weblList &&
					weblList.length > 0 &&
					!showLoading &&
					/*!galleryViewIsEnabled*/ (true ? (
						<WeblControlPanelList
							weblList={weblList}
							sortWebls={sortWebls}
							sortWeblsBy={sortWeblsBy}
							setSortWeblsBy={setSortWeblsBy}
							sortWeblsOrder={sortWeblsOrder}
							setSortWeblsOrder={setSortWeblsOrder}
							deleteWeblCallback={deleteWeblCallback}
							weblToDelete={weblToDelete}
							setWeblToDelete={setWeblToDelete}
							setShowLoading={setShowLoading}
						/>
					) : (
						<WeblControlPanelGallery
							weblList={weblList}
							sortWebls={sortWebls}
							sortWeblsBy={sortWeblsBy}
							setSortWeblsBy={setSortWeblsBy}
							sortWeblsOrder={sortWeblsOrder}
							setSortWeblsOrder={setSortWeblsOrder}
							deleteWeblCallback={deleteWeblCallback}
							weblToDelete={weblToDelete}
							setWeblToDelete={setWeblToDelete}
							setShowLoading={setShowLoading}
						/>
					))}
			</div>
		</>
	);
}

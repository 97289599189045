import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Home/Home.css";
import ErrorDialog from "../ErrorDialog.jsx";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "../ConfirmationDialog.jsx";
import LoadingAnimation from "../LoadingAnimation.jsx";

function ManageAccount({ userType }) {
	const navigate = useNavigate();
	const [showSetSubdomain, setShowSetSubdomain] = useState(false);
	const [currentSubdomain, setCurrentSubdomain] = useState();
	const [updatedSubdomain, setUpdatedSubdomain] = useState();
	const [showDomainWarning, setShowDomainWarning] = useState(false);
	const [showDeleteWarning, setShowDeleteWarning] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user, isLoading } = useAuth0();
	const { logout } = useAuth0();
	const [userCountry, setUserCountry] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("/getUserSubdomain/", {
					params: {
						userid: user.sub,
					},
				});
				setCurrentSubdomain(response.data.subdomain);
				setUpdatedSubdomain(response.data.subdomain);
			} catch (error) {
				console.error("Error fetching user subdomain:", error);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateUserSubdomain = () => {
		// TODO: add consequtive - restriction
		const subdomainRegex =
			/^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;

		if (subdomainRegex.test(updatedSubdomain)) {
			axios.post("/updateUserSubdomain/", {
				userid: user.sub,
				newSubdomain: updatedSubdomain,
			});
		} else {
			console.error("Invalid subdomain format");
			alert(
				"Subdomain can contain maximum 63 characters, it can't start or end with '-' and can't contain consequtive '--' "
			);
		}
	};

	useEffect(() => {
		if (!isLoading) {
			axios.get("/getUserSettings/" + user.sub).then((response) => {
				setUserCountry({
					country: response.data.country,
					continent: response.data.continent,
				});
			});
		}
	}, [isLoading]);

	console.log(userCountry);
	return (
		<div id="manage-account-container">
			<div style={{ fontSize: 36, marginBottom: 18 }}>
				<b>
					{userType
						.replace(/_/g, " ")
						.replace(/\b\w/g, (match) => match.toUpperCase())}
				</b>{" "}
				plan
			</div>
			{!loading && (
				<>
					<div className="links">
						{userCountry && userCountry.continent && (
							<>
								{userType === "free" ? (
									<a
										href="/pricing"
										target="_blank"
										rel="noopener noreferrer"
										className="manage-account-option"
									>
										&gt; Upgrade account to use premium
										features
									</a>
								) : (
									<a
										href={
											userCountry.country ===
											"United Kingdom"
												? "https://billing.stripe.com/p/login/test_3cs8yU7dYbt66acbII"
												: "https://billing.stripe.com/p/login/test_28o7tl87F5U883CbII"
										}
										target="_blank"
										rel="noopener noreferrer"
										className="manage-account-option"
									>
										&gt; Edit current subscription
									</a>
								)}
							</>
						)}

						<div
							className="manage-account-option"
							onClick={() => setShowDeleteWarning(true)}
						>
							&gt; Delete account
						</div>
						<div>
							<span
								className="manage-account-option"
								onClick={() =>
									setShowSetSubdomain(!showSetSubdomain)
								}
							>
								&gt; Set a custom subdomain
							</span>
							{showSetSubdomain && (
								<div id="account-subdomain-field-container">
									<input
										value={updatedSubdomain}
										onChange={(e) => {
											if (userType !== "pro_plus") {
												setShowDomainWarning(true);
											} else {
												setUpdatedSubdomain(
													e.target.value
												);
											}
										}}
										onBlur={(e) => {
											if (
												updatedSubdomain !==
													currentSubdomain &&
												updatedSubdomain
											) {
												updateUserSubdomain();
											}
										}}
									/>
									<div className="prefix">.webler.io</div>
								</div>
							)}
							<ErrorDialog
								open={showDomainWarning}
								onClose={() => setShowDomainWarning(false)}
								dialogText="Upgrade your plan!"
								dialogText2="Upgrade to Pro Plus plan in order to use a custom subdomain for your Webls!"
								confirmLabel="Upgrade my plan"
								confirmAction={() => {
									setShowDomainWarning(false);
									navigate("/pricing");
								}}
								cancelAction={() => {
									setShowDomainWarning(false);
								}}
							/>
						</div>
					</div>
				</>
			)}

			<ConfirmationDialog
				open={showDeleteWarning === true}
				onClose={() => setShowDeleteWarning(false)}
				dialogText="This action is going to permantly delete your account, settings and all webls associated with it. Any active subscription will be terminated. Are you sure you want to continue?"
				confirmAction={() => {
					setLoading(true);
					setShowDeleteWarning(false);
					axios({
						method: "POST",
						url: "/deleteUser/",
						data: {
							userid: user.sub,
						},
					}).then((response) => {
						logout({
							returnTo: "/",
						});
						setLoading(false);
					});
				}}
				cancelAction={() => setShowDeleteWarning(false)}
			/>
			{loading && (
				<LoadingAnimation
					descTextLine1="Deleting your account..."
					descTextLine2="Bye bye Mr Weblerer!"
				/>
			)}
		</div>
	);
}

export default ManageAccount;

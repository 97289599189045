import { useState, useRef, useEffect } from "react";
import DeselectAllIcon from "../../images/tool icons/WeblCreation/deselect_all.svg";
import SelectAllIcon from "../../images/tool icons/WeblCreation/selected_area.svg";
import ApplySuggestionsIcon from "../../images/tool icons/WeblCreation/apply_suggestions.svg";
import "../../css/WeblCreation/dropdown.css";
import { Scrollbars } from "rc-scrollbars";
import { Tooltip } from "@mui/material";

function Dropdown({
	sheetNames,
	selectedTab,
	inputs,
	suggestedInputs,
	setInputs,
	updateVisibleArea,
	setSheetManager,
	selectAllElements,
	spreadsheet,
	visibleArea,
	charts,
	setVisibleArea,
	suggestedVisibleArea,
	resetElementSelections,
	hiddenRanges,
	setHiddenRanges,
}) {
	const [open, setOpen] = useState(false);
	const tableRef = useRef(null);
	const buttonRef = useRef(null);
	const handleOpen = () => {
		setOpen(!open);
		const element = document.getElementsByClassName("sheet-manager-button")[
			selectedTab
		];
		const icon = element.getElementsByClassName("sheet-manager-arrow")[0];
		if (open) {
			icon.style.color = "#000000";
			icon.innerHTML = "▼";
		} else {
			icon.style.color = "#8cc63f";
			icon.innerHTML = "▲";
		}
	};

	const applySuggestions = (e, index) => {
		e.preventDefault();
		let inputsCopy = structuredClone(inputs);
		if (suggestedInputs.length > 0) {
			inputsCopy[index] = suggestedInputs[index];
		}

		if (inputsCopy[index] !== null) {
			setSheetManager(true);
			spreadsheet.setRangeShow(inputsCopy[index], {
				order: index,
				show: true,
			});
			setInputs(inputsCopy);
			updateVisibleArea(inputsCopy, index, visibleArea, setVisibleArea);
		}
	};

	const handleClick = (e) => {
		if (
			tableRef.current &&
			!tableRef.current.contains(e.target) &&
			open &&
			buttonRef.current &&
			!buttonRef.current.contains(e.target)
		) {
			handleOpen();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<>
			<button
				ref={buttonRef}
				className="sheet-manager-button menu-button"
				onClick={handleOpen}
			>
				Sheet Manager <span className="sheet-manager-arrow">▼</span>
			</button>
			{open ? (
				<div ref={tableRef} id="sheet-manager-table-container">
					<table id="sheet-manager-table">
						<thead id="table-header">
							<tr>
								<th>Sheet Name</th>
								<th>
									<span id="actions-span">Actions</span>
								</th>
							</tr>
						</thead>
						<Scrollbars
							style={{
								width: "100%",
							}}
							hideTracksWhenNotNeeded={true}
							autoHeight
						>
							{sheetNames.map((sheetName, index) => (
								<tr
									className="table-row"
									key={"table-row-" + sheetName}
								>
									<td>
										<span className="sheet-number">
											{index + 1}
										</span>
										.
										<span className="sheet-name">
											{sheetName}
										</span>
									</td>
									<td>
										<Tooltip
											title="Select all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													if (selectedTab === 1) {
														setInputs(
															selectAllElements(
																"input",
																index,
																structuredClone(
																	inputs
																),
																spreadsheet,
																visibleArea,
																suggestedInputs,
																charts,
																inputs
															)
														);
													} else {
														setVisibleArea(
															selectAllElements(
																"visibleArea",
																index,
																structuredClone(
																	visibleArea
																),
																spreadsheet,
																visibleArea,
																suggestedVisibleArea,
																charts,
																inputs
															)
														);
													}
												}}
											>
												<img
													src={SelectAllIcon}
													alt="Select all"
												/>
											</div>
										</Tooltip>
										<Tooltip
											title="Deselect all"
											arrow
											placement="bottom"
										>
											<div
												className="dropdown-icon-button"
												onClick={() => {
													if (selectedTab === 1) {
														let inputsCopy =
															structuredClone(
																inputs
															);
														inputsCopy[index] = [];
														setInputs(inputsCopy);
													} else {
														resetElementSelections(
															visibleArea,
															setVisibleArea,
															index
														);
														resetElementSelections(
															inputs,
															setInputs,
															index
														);
														resetElementSelections(
															hiddenRanges,
															setHiddenRanges,
															index
														);
													}
												}}
											>
												<img
													src={DeselectAllIcon}
													alt="Deselect all"
												/>
											</div>
										</Tooltip>
										<Tooltip
											title="Apply suggestions"
											arrow
											placement="bottom"
											style={{
												visibility:
													selectedTab === 1
														? "visible"
														: "hidden",
											}}
										>
											<div
												className="dropdown-icon-button"
												size="small"
												disableRipple
												disableFocusRipple
												disableTouchRipple
												onClick={(event) =>
													applySuggestions(
														event,
														index
													)
												}
											>
												<img
													src={ApplySuggestionsIcon}
													alt="Apply suggestions"
												/>
											</div>
										</Tooltip>
									</td>
								</tr>
							))}
						</Scrollbars>
					</table>
				</div>
			) : null}
		</>
	);
}

export default Dropdown;

import { Tooltip } from "@mui/material";
import { useRef, useEffect } from "react";
import "../../css/WeblCreation/Inputs.css";

function Inputs({
	weblTitle,
	setWeblTitle,
	weblTitles,
	UnavailableIcon,
	AvailableIcon,
	weblDomain,
	setWeblDomain,
	weblDomains,
	subDirectories,
}) {
	const inputRef = useRef(null);
	const domainRef = useRef(null);

	const handleClick = (e) => {
		const elementTitleAvailable = document.getElementById(
			"webl-title-availability"
		);
		if (inputRef.current && !inputRef.current.contains(e.target)) {
			elementTitleAvailable.style.visibility = "hidden";
		} else {
			elementTitleAvailable.style.visibility = "visible";
		}

		const elementDomainAvailable = document.getElementById(
			"webl-domain-availability"
		);
		if (domainRef.current && !domainRef.current.contains(e.target)) {
			elementDomainAvailable.style.visibility = "hidden";
		} else {
			elementDomainAvailable.style.visibility = "visible";
		}
	};

	const restrictCharacters = (e) => {
		e.preventDefault();
		if (weblDomain) {
			if (e.target.value.length > weblDomain.length) {
				let allowedChars = /^[A-Za-z0-9-]+$/;
				let inputValue = e.target.value;
				if (!allowedChars.test(inputValue)) {
					e.target.value = inputValue.slice(0, -1);
				}
			}
		}
		setWeblDomain(e.target.value);
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);

	return (
		<div id="inputs-container">
			<div id="webl-title-container">
				<input
					id="webl-title"
					type="text"
					value={weblTitle}
					placeholder="Give your Webl a title"
					onChange={(e) => {
						setWeblTitle(e.target.value);
					}}
					ref={inputRef}
					maxLength="25"
				/>
				<div id="webl-title-availability">
					{typeof weblTitles !== "undefined" &&
						weblTitle &&
						(weblTitles.includes(weblTitle.trim()) ? (
							<Tooltip
								title={
									"Webl " +
									weblTitle.trim() +
									" already exists on this account."
								}
							>
								<img
									className="webl-title-icon"
									src={UnavailableIcon}
									alt="Available Icon"
								/>
							</Tooltip>
						) : (
							<Tooltip
								title={weblTitle.trim() + " is available."}
							>
								<img
									className="webl-title-icon"
									src={AvailableIcon}
									alt="Available Icon"
								/>
							</Tooltip>
						))}
				</div>
			</div>
			<div id="webl-domain-container">
				<input
					id="webl-domain"
					type="text"
					value={weblDomain}
					placeholder="Give you Webl a custom website path"
					onChange={(e) => {
						restrictCharacters(e);
					}}
					maxLength="30"
					ref={domainRef}
				/>
				<div id="webl-domain-availability">
					{typeof weblDomains !== "undefined" &&
						weblDomain &&
						(weblDomains
							.map((domain) => domain.toLowerCase())
							.includes(weblDomain.toLowerCase()) ||
						weblDomain.startsWith("-") ||
						weblDomain.endsWith("-") ||
						weblDomain.includes("--") ||
						subDirectories.includes(weblDomain.toLowerCase()) ? (
							<Tooltip
								title={
									weblDomains.includes(weblDomain.trim())
										? "Webl " +
										  weblDomain +
										  " already exists."
										: weblDomain.startsWith("-") ||
										  weblDomain.endsWith("-")
										? "Webl domain cannot start or end with '-'"
										: weblDomain.includes("--")
										? "Webl domain cannot contain consequtive '-'"
										: "Webl domain cannot be a subdirectory"
								}
							>
								<img
									className="webl-title-icon"
									src={UnavailableIcon}
									alt="Available Icon"
								/>
							</Tooltip>
						) : (
							<Tooltip title={weblDomain + " is available."}>
								<img
									className="webl-title-icon"
									src={AvailableIcon}
									alt="Available Icon"
								/>
							</Tooltip>
						))}
				</div>
			</div>
		</div>
	);
}

export default Inputs;

import "../css/Home/Home.css";
import "../css/Home/HomeScrollbar.css";
import { Scrollbars } from "rc-scrollbars";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import CornerIcon from "../images/icons/corner.svg";
import HomeFooter from "../components/Home/HomeFooter.jsx";
import HomeHeader from "../components/Home/HomeHeader.jsx";
import WeblControlPanel from "../components/Home/WeblControlPanel.jsx";
import WeblUploader from "../components/Home/WeblUploader.jsx";
import ManageAccount from "../components/Home/ManageAccount.jsx";
import LogoutButton from "../components/LogoutButton.jsx";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import axios from "axios";

function Home() {
	const { state } = useLocation();
	const [showManageAccount, setShowManageAccount] = useState(false);
	const { user } = useAuth0();
	const [userType, setUserType] = useState(null);
	useEffect(() => {
		if (
			state === null ||
			!Object.keys(state).includes("showManageAccount")
		) {
			setShowManageAccount(false);
		} else {
			setShowManageAccount(state.showManageAccount);
		}
	}, [state]);

	/**
	 * Sends a POST request to create user settings for the current user.
	 * It makes a POST request to the "/createUserSettings/" endpoint with user-specific data.
	 * This may be used for initializing or updating user settings.
	 */
	useEffect(() => {
		axios({
			method: "POST",
			url: "/createUserSettings/",
			data: {
				userid: user.sub,
				user_email: user.email,
			},
		}).then((response) => {
			setUserType(response.data.user_type);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.sub]);
	return (
		<div id="home-page">
			<Scrollbars
				hideTracksWhenNotNeeded={true}
				style={{ width: "100vw", height: "100vh" }}
			>
				<HomeHeader />
				<div id="home-container">
					<div id="home-outline-top">
						<div id="home-outline-top-left">
							<img
								src={CornerIcon}
								alt="corner-icon"
								loading="lazy"
							/>
						</div>
						<div id="home-outline-top-right" />
					</div>
					<div id="home-container-content">
						<div id="home-top-container">
							<div id="home-top-main-container">
								<span>
									{/* Effortlessly share and transform your <br />
									spreadsheets into dynamic web applications */}
								</span>
								<div id="home-top-buttons-container">
									<div
										className="logout-button"
										onClick={() => {
											setShowManageAccount(
												!showManageAccount
											);
										}}
									>
										{!showManageAccount ? (
											<>
												<span>MANAGE ACCOUNT </span>
												<ManageAccountsOutlinedIcon />
											</>
										) : (
											<>
												<span>BACK TO HOME</span>
												<HomeOutlinedIcon />
											</>
										)}
									</div>
									<LogoutButton />
								</div>
							</div>
						</div>
						{!showManageAccount || !userType ? (
							<>
								<WeblUploader />
								<WeblControlPanel />
							</>
						) : (
							<ManageAccount userType={userType} />
						)}
					</div>
				</div>
				<HomeFooter />
			</Scrollbars>
		</div>
	);
}

export default withAuthenticationRequired(Home, {});

import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Webl from "../components/ViewWebl/Webl.jsx";
import WeblPasswordInput from "../components/ViewWebl/WeblPasswordInput.jsx";
import WeblHeader from "../components/ViewWebl/WeblHeader.jsx";

function PreviewWebl() {
	const navigate = useNavigate();
	const { weblDomain } = useParams();
	const { user, isLoading } = useAuth0();
	const [csrfToken, setCsrfToken] = useState(null);
	const [passwordInputCorrect, setPasswordInputCorrect] = useState(null);
	const [passwordInputValue, setPasswordInputValue] = useState(0);
	const [redirectioncount, setRedirectionCount] = useState(0);
	const [currentUserIsTheOwner, setCurrentUserIsTheOwner] = useState(false);
	const [weblTitle, setWeblTitle] = useState();
	const [weblid, setWeblid] = useState();
	const [weblUnavailable, setWeblUnavailable] = useState(false);
	const [weblShowPassword, setWeblShowPassword] = useState(false);
	const redirectionAfter = 5; // seconds
	const [removeWeblerLogo, setRemoveWeblerLogo] = useState(null);
	const [boxedContainer, setBoxedContainer] = useState();

	useEffect(() => {
		if (!isLoading) {
			axios({
				method: "POST",
				url: "/getWeblData/",
				data: { previewMode: true, weblDomain: weblDomain },
			})
				.then((response) => {
					console.log(response.data.boxed_container);
					setBoxedContainer(response.data.boxed_container);
					setWeblid(response.data.weblid);
					setWeblTitle(response.data.title);
					setCurrentUserIsTheOwner(response.data.userId === user.sub);
					setRemoveWeblerLogo(response.data.remove_webler_logo);
					if (response.data.password_protect) {
						if (user && response.data.userId === user.sub) {
							setWeblShowPassword(false);
						} else {
							setWeblShowPassword(true);
						}
					} else {
						setWeblShowPassword(false);
					}
				})
				.catch((error) => {
					setWeblUnavailable(true);
					if (error.response) {
						// The client was given an error response (5xx, 4xx)
						console.error(
							error.response.status +
								" " +
								error.response.statusText
						);

						if (error.response.status === 404) {
							navigate("/error");
						} else {
							// Display a user-friendly error message for other errors
							alert("An error occurred. Please try again later.");
						}
					} else if (error.request) {
						// The client never received a response, and the request was never left
						console.error(error.request);
						alert(
							"An error occurred. Please check your internet connection."
						);
					} else {
						// Anything else
						console.error("error", error.message);

						if (error.statusCode === 404) {
							navigate("/error");
						} else {
							alert(
								"An unexpected error occurred. Please try again later."
							);
						}
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, weblDomain, isLoading]);

	useEffect(() => {
		axios({
			method: "GET",
			url: "/csrf/",
			withCredentials: true,
			headers: {
				"Access-Control-Allow-Origin": "*",
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setCsrfToken(response.data.csrfToken);
				}
			})
			.catch((error) => {
				if (error.response) {
					// The client was given an error response (5xx, 4xx)
					console.error(
						error.response.status + " " + error.response.statusText
					);

					if (error.response.status === 404) {
						navigate("/error");
					} else {
						// Display a user-friendly error message for other errors
						alert("An error occurred. Please try again later.");
					}
				} else if (error.request) {
					// The client never received a response, and the request was never left
					console.error(error.request);
					alert(
						"An error occurred. Please check your internet connection."
					);
				} else {
					// Anything else
					console.error("error", error.message);

					if (error.statusCode === 404) {
						navigate("/error");
					} else {
						alert(
							"An unexpected error occurred. Please try again later."
						);
					}
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (redirectioncount === redirectionAfter) return; // Exit early when countdown reaches 0

		const timer = setInterval(
			() => setRedirectionCount((prevCount) => prevCount + 1),
			1000
		); // Decrease redirectioncount every second

		return () => clearInterval(timer);
	}, [redirectioncount]);

	const submitPassword = () => {
		axios({
			method: "POST",
			url: "/checkWeblPassword/",
			data: {
				webl_domain: weblDomain,
				entered_password: passwordInputValue,
			},
			headers: {
				"X-CSRFToken": csrfToken,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setPasswordInputCorrect(response.data);
					setWeblShowPassword(!response.data);
				}
			})
			.catch((error) => {
				if (error.response) {
					// The client was given an error response (5xx, 4xx)
					console.error(
						error.response.status + " " + error.response.statusText
					);

					if (error.response.status === 404) {
						navigate("/error");
					} else {
						// Display a user-friendly error message for other errors
						alert("An error occurred. Please try again later.");
					}
				} else if (error.request) {
					// The client never received a response, and the request was never left
					console.error(error.request);
					alert(
						"An error occurred. Please check your internet connection."
					);
				} else {
					// Anything else
					console.error("error", error.message);

					if (error.statusCode === 404) {
						navigate("/error");
					} else {
						alert(
							"An unexpected error occurred. Please try again later."
						);
					}
				}
			});
	};

	return (
		<>
			{boxedContainer ? (
				<div
					id="webl-wrapper"
					style={{
						width: "100%",
						height: "100%",
						position: "relative",
						overflow: "hidden",
					}}
				>
					{weblUnavailable && navigate("/error/unavailableWebl")}
					{!weblUnavailable && !isLoading && (
						<>
							{removeWeblerLogo !== null && (
								<WeblHeader
									user={user}
									weblTitle={weblTitle}
									removeWeblerLogo={removeWeblerLogo}
								/>
							)}

							{weblShowPassword ? (
								<WeblPasswordInput
									passwordInputCorrect={passwordInputCorrect}
									setPasswordInputValue={
										setPasswordInputValue
									}
									submitPassword={submitPassword}
								/>
							) : (
								<Webl
									weblid={weblid}
									weblTitle={weblTitle}
									user={user}
									previewMode={true}
								/>
							)}
						</>
					)}
				</div>
			) : (
				<div id="webl-wrapper">
					{weblUnavailable && navigate("/error/unavailableWebl")}
					{!weblUnavailable && !isLoading && (
						<>
							{removeWeblerLogo !== null && (
								<WeblHeader
									user={user}
									weblTitle={weblTitle}
									removeWeblerLogo={removeWeblerLogo}
								/>
							)}

							{weblShowPassword ? (
								<WeblPasswordInput
									passwordInputCorrect={passwordInputCorrect}
									setPasswordInputValue={
										setPasswordInputValue
									}
									submitPassword={submitPassword}
								/>
							) : (
								<Webl
									weblid={weblid}
									weblTitle={weblTitle}
									user={user}
									previewMode={false}
								/>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
}

export default PreviewWebl;

import React from "react";
import "../../css/ConfirmationDialog.css";
import { TextField } from "@mui/material";

function PasswordDialog({
	open,
	onClose,
	confirmAction,
	cancelAction,
	passwordToShow,
	setPasswordInputValue,
	passwordProtectEnabled,
	closePasswordDialog,
	setPassword,
	setPasswordProtectEnabled,
	passwordInputValue,
}) {
	return (
		<>
			{open && (
				<div
					className="custom-dialog"
					style={{ zIndex: "1050" }}
					onClick={(e) =>
						e.target.className === "custom-dialog" && onClose()
					}
				>
					<div
						className="custom-dialog-content custom-confirmation-dialog-content"
						style={{ zIndex: "1051" }}
					>
						To protect this Webl, please enter your password below:
						<TextField
							type="password"
							id="password-input"
							placeholder="Password"
							size="small"
							defaultValue={passwordToShow && passwordToShow}
							onClick={(e) => {
								if (e.target.id === "password-input") {
									document.getElementById(
										"password-input"
									).value = "";
									setPasswordInputValue("");
								}
							}}
							onChange={(e) => {
								setPasswordInputValue(e.target.value);
							}}
							fullWidth
							style={{ marginTop: 20 }}
						/>
						<div className="confirmation-buttons">
							<button
								className="cancel_btn"
								id="pass_btn"
								onClick={cancelAction}
							>
								Cancel
							</button>
							{passwordProtectEnabled && (
								<button
									className="cancel_btn"
									id="pass_btn"
									onClick={() => {
										closePasswordDialog();
										setPassword(null);
										setPasswordProtectEnabled(false);
									}}
								>
									Remove
								</button>
							)}
							<button
								className="confirm_btn"
								id="pass_btn"
								onClick={confirmAction}
								disabled={!passwordInputValue}
							>
								Set
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PasswordDialog;

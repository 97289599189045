import { useState } from "react";
import { TextField } from "@mui/material";
import { uploadFileFromOnedriveLink } from "../../functions/HomeFunctions";
import "../../css/Home/OnedriveLinkField.css";

export default function OnedriveLinkField({
	setShowLoading,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setSheetNamesDictionary,
	navigate,
	closeFieldFunction,
}) {
	const [oneDriveLink, setOneDriveLink] = useState();

	return (
		<div
			className="custom-dialog"
			onClick={(e) =>
				e.target.className === "custom-dialog" && closeFieldFunction()
			}
		>
			<div
				className="custom-dialog-content"
				id="onedrive-link-field-container"
			>
				<TextField
					label="Add a OneDrive link"
					placeholder="Paste your link here"
					size="small"
					type="url"
					onChange={(e) => {
						setOneDriveLink(
							e.target.value.startsWith("https://")
								? e.target.value
								: "https://" + e.target.value
						);
					}}
					fullWidth
				/>
				<button
					className="confirm_btn"
					onClick={(e) =>
						uploadFileFromOnedriveLink(
							e,
							oneDriveLink,
							setShowLoading,
							setFileName,
							userFolder,
							setFileID,
							setFile,
							setSheetNamesDictionary,
							navigate
						)
					}
				>
					Upload
				</button>
			</div>
		</div>
	);
}

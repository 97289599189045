import "../../css/WeblCreation/style.css";
import InitialTheme from "../../images/icons/Theme Icons/Icon_Initial.svg";
import IconTheme1 from "../../images/icons/Theme Icons/Icon_theme_01.svg";
import IconTheme2 from "../../images/icons/Theme Icons/Icon_theme_02.svg";
import IconTheme3 from "../../images/icons/Theme Icons/Icon_theme_03.svg";
import IconTheme4 from "../../images/icons/Theme Icons/Icon_theme_04.svg";
import IconTheme5 from "../../images/icons/Theme Icons/Icon_theme_05.svg";
import IconTheme6 from "../../images/icons/Theme Icons/Icon_theme_06.svg";
import IconTheme7 from "../../images/icons/Theme Icons/Icon_theme_07.svg";
import IconTheme8 from "../../images/icons/Theme Icons/Icon_theme_08.svg";
import PreviewInitial from "../../images/themes/previews/preview 00 initial.svg";
import PreviewTheme1 from "../../images/themes/previews/preview 01 halite blue.svg";
import PreviewTheme2 from "../../images/themes/previews/preview 02 alma.svg";
import PreviewTheme3 from "../../images/themes/previews/preview 03 burgundy.svg";
import PreviewTheme4 from "../../images/themes/previews/preview 04 Salmon.svg";
import PreviewTheme5 from "../../images/themes/previews/preview 05 levander.svg";
import PreviewTheme6 from "../../images/themes/previews/preview 06 forest green.svg";
import PreviewTheme7 from "../../images/themes/previews/preview 07 CRT.svg";
import PreviewTheme8 from "../../images/themes/previews/preview 08 Note.svg";

import { useState, useRef, useEffect } from "react";
import CustomSwitch from "../CustomSwitch";

function Style({
	hideOrUnhideMenu,
	initialThemeSettings,
	setInitialThemeSettings,
	initialThemeSettingsLabels,
	themeNames,
	chosenTheme,
	setChosenTheme,
}) {
	const IconThemes = [
		InitialTheme,
		IconTheme1,
		IconTheme2,
		IconTheme3,
		IconTheme4,
		IconTheme5,
		IconTheme6,
		IconTheme7,
		IconTheme8,
	];

	const PreviewThemes = [
		PreviewInitial,
		PreviewTheme1,
		PreviewTheme2,
		PreviewTheme3,
		PreviewTheme4,
		PreviewTheme5,
		PreviewTheme6,
		PreviewTheme7,
		PreviewTheme8,
	];

	useEffect(() => {
		if (chosenTheme) setThemeToChoose(chosenTheme);
	}, [chosenTheme]);
	const [open, setOpen] = useState(false);
	const [themeToChoose, setThemeToChoose] = useState(
		chosenTheme ? chosenTheme : themeNames[0]
	);
	const styleContainerRef = useRef(null);
	const buttonRef = useRef(null);

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleChangeSwitch = (setting) => {
		const updatedSettingsStatus = {
			...initialThemeSettings, // Create a copy of the object
		};
		updatedSettingsStatus[setting] = !updatedSettingsStatus[setting]; // Modify the copy
		setInitialThemeSettings(updatedSettingsStatus); // Update the state with the modified copy
	};

	const handleSelectTheme = (theme) => {
		const oldSelectionElement = document.getElementById(
			themeToChoose.value
		);
		const newSelectionElement = document.getElementById(theme.value);
		oldSelectionElement.style.border = "3px solid transparent";
		newSelectionElement.style.border = "3px solid var(--webl_lightgreen)";
		setThemeToChoose(theme);
	};

	const handleClick = (e) => {
		if (
			styleContainerRef.current &&
			!styleContainerRef.current.contains(e.target) &&
			open &&
			buttonRef.current &&
			!buttonRef.current.contains(e.target)
		) {
			setChosenTheme(chosenTheme);
			setThemeToChoose(chosenTheme);
			setOpen(false);
		}
	};

	const handleMouseEnter = (themeImageId) => {
		const element = document.getElementById(themeImageId);
		element.style.display = "block";
	};

	const handleMouseLeave = (themeImageId) => {
		const element = document.getElementById(themeImageId);
		element.style.display = "none";
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<div>
			<button
				className="basic-button"
				ref={buttonRef}
				onClick={(event) => {
					hideOrUnhideMenu(event, 2);
					handleOpen(event);
				}}
			>
				Themes & Style
			</button>
			{open ? (
				<div>
					<div id="themes-container" ref={styleContainerRef}>
						<div id="themes-container-left">
							<div id="title-container">Themes & Style</div>
							{/* <div id="selected-theme-container">
								{themeToChoose.label}
							</div> */}

							<div id="themes-switches-container">
								<div id="customize-container">
									Customise the selected theme <br />
									by adding:
								</div>
								<div>
									{[
										"tableSpacing",
										"gridlines",
										"inputBorders",
										"roundBorders",
									].map((setting) => (
										<div
											className="switch"
											key={
												"switch-" +
												initialThemeSettingsLabels[
													setting
												]
											}
										>
											<CustomSwitch
												checked={
													initialThemeSettings[
														setting
													]
												}
												onChange={() =>
													handleChangeSwitch(setting)
												}
												height={13}
												width={32}
												backgroundColorChecked={
													"#8cc63f"
												}
												translate={13}
											/>
											<span>
												{
													initialThemeSettingsLabels[
														setting
													]
												}
											</span>
										</div>
									))}
								</div>
							</div>
							<div id="themes-footnote-container">
								<p>
									<b>Note:</b>
								</p>
								<p>
									The selected theme and settings will only be
									applied to your published Webl.
								</p>
							</div>
						</div>
						<div id="themes-container-right">
							<div id="themes-list">
								{themeNames.map((theme, index) => (
									<div key={"theme-" + theme.label}>
										<img
											id={theme.value}
											src={IconThemes[index]}
											alt={theme.label}
											onClick={() =>
												handleSelectTheme(theme)
											}
											className={
												"theme-icon " +
												(themeToChoose &&
												themeToChoose.value ===
													theme.value
													? "selected-theme"
													: "")
											}
											onMouseOver={() =>
												handleMouseEnter(
													"preview_theme_" +
														(index + 1).toString()
												)
											}
											onMouseOut={() =>
												handleMouseLeave(
													"preview_theme_" +
														(index + 1).toString()
												)
											}
										/>
										<div className="theme-title">
											{theme.label === "Alma"
												? "Alma Blue"
												: theme.label === "Levander"
												? "Lavender"
												: theme.label === "Initial"
												? "No Custom Style"
												: theme.label}
											{/* {theme.label} */}
										</div>
									</div>
								))}
							</div>
							<div
								id="themes-buttons"
								className="confirmation-buttons"
							>
								<button
									className="cancel_btn"
									onClick={() => {
										setChosenTheme(chosenTheme);
										setThemeToChoose(chosenTheme);
										setOpen(false);
									}}
								>
									Cancel
								</button>
								<button
									className="confirm_btn"
									onClick={() => {
										setChosenTheme(themeToChoose);
										setOpen(false);
									}}
								>
									Apply
								</button>
							</div>
						</div>
					</div>
					<img
						src={PreviewInitial}
						alt="Initial Preview"
						id="initial_image"
						className="theme_previews"
					/>
					{PreviewThemes.map((theme, index) => (
						<img
							src={theme}
							alt={"Theme " + (index + 1).toString() + " Preview"}
							className="theme_previews"
							id={"preview_theme_" + (index + 1).toString()}
							key={"preview_theme_" + (index + 1).toString()}
						/>
					))}
				</div>
			) : null}
		</div>
	);
}

export default Style;

import { useState, useEffect } from "react";

const Snackbar = ({ open, message, autoHideDuration, onClose }) => {
	const [visible, setVisible] = useState(open);

	useEffect(() => {
		setVisible(open);
	}, [open]);

	useEffect(() => {
		let timeoutId;

		if (visible) {
			timeoutId = setTimeout(() => {
				setVisible(false);
				onClose();
			}, autoHideDuration);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [visible, autoHideDuration, onClose]);

	return visible ? (
		<div
			className="custom-snackbar"
			style={{
				position: "fixed",
				bottom: "10px",
				left: "10px",
				backgroundColor: "var(--webl_black)",
				color: "var(--webl_lightgray)",
				padding: "15px",
				borderRadius: "5px",
				boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
			}}
		>
			<div className="snackbar-content">
				<span style={{ color: "#fff" }}>{message}</span>
			</div>
		</div>
	) : null;
};

export default Snackbar;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
function PricingPage() {
	const { user, isLoading } = useAuth0();
	const [country, setCountry] = useState(null);

	useEffect(() => {
		if (!isLoading) {
			axios.get("/getUserSettings/" + user.sub).then((response) => {
				setCountry(response.data.country);
			});
		}
	}, [isLoading]);
	return (
		<>
			{country && (
				<>
					{country === "United Kingdom" ? (
						<stripe-pricing-table
							pricing-table-id={
								process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_UK
							}
							publishable-key={
								process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UK
							}
						></stripe-pricing-table>
					) : (
						<stripe-pricing-table
							pricing-table-id={
								process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
							}
							publishable-key={
								process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
							}
						></stripe-pricing-table>
					)}
				</>
			)}
		</>
	);
}

export default PricingPage;

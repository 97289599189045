import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const StripeSuccessPage = () => {
	const navigate = useNavigate();
	const { sessionId } = useParams();
	const { user } = useAuth0();

	useEffect(() => {
		if (user) {
			axios({
				method: "POST",
				url: "/updateUserSettings/",
				data: {
					userid: user.sub,
					sessionId: sessionId,
				},
			});

			let timeoutId;
			timeoutId = setTimeout(() => {
				navigate("/", {
					state: {
						showManageAccount: true,
					},
				});
			}, 1500);

			return () => {
				clearTimeout(timeoutId);
			};
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return <></>;
};

export default StripeSuccessPage;

import { useEffect } from "react";
import { Scrollbars } from "rc-scrollbars";
import "../../css/Webl/WeblScrollbar.css";
import LoadingAnimation from "../LoadingAnimation";
import {
	getTableCornerIds,
	getLargestCellDimensions,
	getImageHeight,
	getImageWidth,
} from "../../functions/WeblFunctions";

function WeblContent({
	weblValuesReady,
	spreadsheetHtml,
	sheetToShow,
	centeredLayout,
	selectedElementsRange,
	containerRef,
	zoomWebl,
	boxedContainer,
	customScrollbar,
}) {
	/**
	 * Calculates and dimensions of the webl container where the table of the final webl will be placed.
	 */
	useEffect(() => {
		const table = document.getElementsByTagName("table")[0];
		if (table && window.innerWidth && selectedElementsRange) {
			const [
				leftest_cell_id,
				rightest_cell_id,
				toppest_cell_id,
				bottomest_cell_id,
			] = getTableCornerIds(table, sheetToShow, selectedElementsRange);
			if (
				leftest_cell_id &&
				rightest_cell_id &&
				toppest_cell_id &&
				bottomest_cell_id
			) {
				const tableOffsetLeft =
					document.getElementById(leftest_cell_id).offsetLeft;

				const [largestCellHeight, largestCellWidth] =
					getLargestCellDimensions(table);

				let tableWidth =
					document.getElementById(rightest_cell_id).offsetLeft +
					document.getElementById(rightest_cell_id).offsetWidth +
					getImageWidth(document.getElementById(rightest_cell_id));
				const weblHtml =
					document.getElementsByClassName("webl-html")[0];
				if (tableWidth > 0) {
					// const tableOffsetTop =
					// 	document.getElementById(toppest_cell_id).offsetTop;
					let tableHeight =
						document.getElementById(bottomest_cell_id).offsetTop +
						document.getElementById(bottomest_cell_id)
							.offsetHeight +
						getImageHeight(
							document.getElementById(bottomest_cell_id)
						);

					largestCellHeight > tableHeight &&
						(tableHeight = largestCellHeight);
					largestCellWidth > tableWidth &&
						(tableWidth = largestCellWidth);

					const weblContainer =
						document.getElementById("webl-container");
					if (weblHtml && weblContainer) {
						if (centeredLayout) {
							let marginLeftValue =
								(window.innerWidth -
									tableWidth -
									tableOffsetLeft) /
								2;
							marginLeftValue < 0 && (marginLeftValue = 0);
							weblHtml.style.marginLeft = marginLeftValue;
							weblHtml.style.height = tableHeight + "px";
							weblHtml.style.width = tableWidth + "px";

							// Used calc with % for responsiveness
							weblContainer.style.width = "1000px";
							weblContainer.style.overflowX = "auto";
							weblContainer.style.overflowY = "auto";
						}
					}
				}
			}
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		spreadsheetHtml,
		sheetToShow,
		weblValuesReady,
		window.innerWidth,
		centeredLayout,
		selectedElementsRange,
	]);

	useEffect(() => {
		const weblHeaderInnerContainer = document.getElementById(
			"webl-header-inner-container"
		);
		const weblOutlineTitle = document.getElementById("webl-outline-title");
		const weblManagerInnerContainer = document.getElementById(
			"webl-manager-inner-container"
		);
		if (centeredLayout) {
			weblHeaderInnerContainer.style.width = "1000px";
			weblOutlineTitle.style.width = "1000px";
			weblManagerInnerContainer.style.width = "1000px";
		}
	}, [centeredLayout]);

	useEffect(() => {
		if (
			weblValuesReady &&
			document.getElementsByClassName("webl-html")[0]
		) {
			const height = document.getElementById("webl-container");
			const weblHTML = document.getElementsByClassName("webl-html")[0];
			if (height) {
				if (boxedContainer && weblHTML) {
					const webl_height =
						(document.getElementsByClassName("webl-html")[0]
							.clientHeight +
							2 * 32) *
							(zoomWebl / 100) +
						"px";
					weblHTML.style.height = webl_height;
				} else {
					if (!centeredLayout) {
						height.style.height =
							(document.getElementsByClassName("webl-html")[0]
								.clientHeight +
								2 * 32) *
								(zoomWebl / 100) +
							"px";
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weblValuesReady]);

	return (
		<>
			{boxedContainer ? (
				<div id="webl-content-wrapper">
					{!weblValuesReady && (
						<LoadingAnimation
							descTextLine1={"Loading in progress"}
							descTextLine2={
								"Loading time will vary depending on your file's complexity."
							}
						/>
					)}
					<div
						id="webl-container"
						className={
							!customScrollbar ? "outside_box" : "inside_box"
						}
						style={{
							display: weblValuesReady ? "block" : "none",
							marginLeft: centeredLayout
								? "auto"
								: "7px auto 0 7px",
							width: "calc(100% - 2 * 32px)",
							height: "calc(100% - 32px)",
						}}
					>
						<Scrollbars hideTracksWhenNotNeeded={true}>
							<div
								ref={containerRef}
								dangerouslySetInnerHTML={{
									__html: spreadsheetHtml[sheetToShow],
								}}
								className="webl-html"
								style={{
									marginBottom: 20,
									transform: "scale(" + zoomWebl / 100 + ")",
									transformOrigin: "top left",
								}}
							/>
						</Scrollbars>
					</div>
				</div>
			) : (
				<div id="webl-fullscreen-wrapper">
					{!weblValuesReady && (
						<div style={{ marginTop: "25%", marginBottom: "25%" }}>
							<LoadingAnimation
								descTextLine1={"Loading in progress"}
								descTextLine2={
									"Loading time will vary depending on your file's complexity."
								}
							/>
						</div>
					)}
					<div
						id="webl-container"
						style={{
							display: weblValuesReady ? "block" : "none",
							marginLeft: centeredLayout
								? "auto"
								: "7px auto 0 7px",
							overflowY: "hidden",
							overflowX: "auto",
						}}
					>
						<div
							ref={containerRef}
							dangerouslySetInnerHTML={{
								__html: spreadsheetHtml[sheetToShow],
							}}
							className="webl-html"
							style={{
								marginBottom: 20,
								transform: "scale(" + zoomWebl / 100 + ")",
								transformOrigin: "top left",
							}}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default WeblContent;
